<template>
  <el-container>
      <!-- 左侧导航 -->
    <el-aside :class="isCollapse ? 'collapse':''">
        <nav-menu :is-collapse="isCollapse" ></nav-menu>
     </el-aside>
    <el-container>
        <!-- 头部信息 -->
      <el-header>
          <admin-header :is-collapse="isCollapse" @change-collapse="changeCollapse"></admin-header>
      </el-header>
      <el-main>
          <!-- 是嵌套路由的出口 -->
          <router-view></router-view>
      </el-main>
    </el-container>
  </el-container>
</template>

<script>
import NavMenu from './NavMenu.vue';
import AdminHeader from './AdminHeader.vue';
export default {
  data(){
    return {
      isCollapse: false
    }
  },
  components: { NavMenu,AdminHeader },
  methods: {
    changeCollapse(){
      this.isCollapse = !this.isCollapse
    }
  }
  };
</script>

<style>
.el-header {
  background-color: #b3c0d1;
  color: #333;
  /* line-height: 60px; */
  height: 100px!important;
}

.el-aside {
  background-color: #d3dce6;
  color: #333;
  text-align: center;
  width: 200px!important;
  /* line-height: 200px; */
}
.collapse{
  width: 50px!important;
}

.el-main {
  background-color: #e9eef3;
  color: #333;
  /* text-align: center; */
  /* line-height: 160px; */
}

body > .el-container {
  margin-bottom: 40px;
  height: 100%;
}
</style>