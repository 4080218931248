<template>
  <!-- 
            ？. 表示：可选链操作符( ?. )允许读取位于连接对象链深处的属性的值，
            而不必明确验证链中的每个引用是否有效 

            ？.的个人理解 打个比方就是判断对象的某个属性是否存在，如果存在那么就返回整个属性的值，否则返回undefined
        -->
  <!-- {{route?.meta?.title}} -->

  <!-- 处理只有一级菜单的情况 -->
  <el-menu-item v-if="result.meta.level" :index="getIndexOne(result)">
    <i :class="result.meta.icon"></i>
    <span slot="title">{{ result.meta.title }}</span>
  </el-menu-item>

  <!-- 处理有二级菜单的情况 -->
  <el-submenu v-else :index="result.path">
    <template slot="title">
      <i :class="result.meta.icon"></i>
      <span slot="title">{{ result.meta.title }}</span>
    </template>

    <el-menu-item
      :index="result.path + '/' + item.path"
      v-for="item in result.children"
      :key="item.path"
    >
      <i :class="item.meta.icon"></i>
      <span slot="title">{{ item.meta.title }}</span>
    </el-menu-item>
  </el-submenu>
</template>

<script>
import _ from 'lodash'
export default {
  props: {
    route: Object,
  },
  computed: {
    // 处理level=1的菜单的inde值
    getIndexOne() {
      return function (item) {
        if (item.path === "/") {
          return "/" + item.children[0].path;
        }
        return item.path + "/" + item.children[0].path;
      };
    },
    //过滤掉hidden=true的菜单
    result(){
        let route = _.cloneDeep(this.route)
        let children = route.children.filter(item=>!item.hidden)
        route.children = children
        return route
    }
  },
};
</script>

<style>
</style>