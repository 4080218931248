<template>
  <div class="header">
    <div class="top">
      <div class="left" @click="toggle">
        <!-- 展开 -->
        <i class="el-icon-s-unfold" v-if="isCollapse"></i>
        <!-- 折叠 -->
        <i class="el-icon-s-fold" v-else></i>
      </div>

      <!-- 面包屑导航组件 -->
      <bread-crumb></bread-crumb>
      <el-button class="quit" @click="quit" type="primary">退出登录</el-button>
    </div>
    
    <!-- 标签页(便签页) -->
    <hr>
    <tabs></tabs>
  </div>
</template>

<script>
import BreadCrumb from "./BreadCrumb.vue";
import Tabs from "./Tabs.vue";
export default {
  components: {
    Tabs,
    BreadCrumb,
  },
  props: {
    isCollapse: Boolean,
  },
  methods: {
    // 切换菜单的折叠和展开
    toggle() {
      console.log(11);
      this.$emit("change-collapse");
    },
    // 退出登录
    quit() {
      sessionStorage.clear();
      this.$router.push({
        path: "/login",
        query: {
            redirectUrl: this.$route.path
        }
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.header {
    height: 100px;
}
.top {
  line-height: 30px;
  display: flex;
  .el-breadcrumb {
    padding-left: 100px;
    width: 600px;
    line-height: 30px;
  }
  .quit{
    position: absolute;
    right: 100px;
  }
}
</style>