<template>
  <el-menu
    :default-active="getPath"
    class="el-menu-vertical-demo"
    :collapse="isCollapse"
    :router="true"
    background-color="#545c64"
    text-color="#fff"
    active-text-color="#ffd04b"
    :collapse-transition="false"
  >
    <nav-item
      v-for="route in routeList"
      :key="route.path"
      :route="route"
    ></nav-item>
  </el-menu>
</template>

<script>
import asyncRoutes from "@/router/asyncRoutes";
import NavItem from "./NavItem.vue";

export default {
  components: { NavItem },
  props: {
    isCollapse: Boolean,
  },
  methods: {},
  computed: {
    // 处理刷新时导航菜单高亮的效果
    getPath() {
      let { fullPath } = this.$route;
      // 如果当前路由是editStudent,则让学员信息高亮
      if (fullPath === "/studentAdmin/editStudent") {
        return "/studentAdmin/stuInfo";
      }
      return fullPath;
    },
    routeList(){
      let role = sessionStorage.getItem("role")
      let result = asyncRoutes.filter(route=>{
         let index = route.meta.role.findIndex(item=>{
          return item === role
         })
         return index!==-1
      })
      return result
    }
  },
};
</script>

<style lang="scss" scoped>
/* .el-menu-vertical-demo:not(.el-menu--collapse) {
  width: 200px;
} */
.el-menu-vertical-demo {
  width: 200px;
  height: 100%;
  box-sizing: border-box;
}
.el-menu--collapse {
  width: 50px !important;
}
</style>