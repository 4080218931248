<template>
  <div class="tabs">
    <!-- <el-tag  closable type="">首页</el-tag>
    <el-tag  closable type="">图片上传</el-tag>
    <el-tag  closable type="success">文章管理</el-tag> -->
    <el-tag
      :type="activeIndex === index ? 'success' : ''"
      @click="skip(item, index)"
      @close="handleClose(index)"
      v-for="(item, index) in tabList"
      :key="item.path"
      :closable="index !== 0"
      >{{ item.title }}</el-tag
    >
  </div>
</template>

<script>
/**
 * 分类讨论：
 * 1. 首次进入后台管理主页   ['首页']
 * 2. 切换路由，先判断是否已存在，
 *      如果不存在则在tab标签上添加当前路由的标题  ['首页','公告管理','图片上传'] , 在标题上还要绑定路由，
 *      如果已经存在，则只需要获取路由对应的索引，处理高亮
 * 3. 控制高亮
 * 4. 删除： 当前标签从tabList中删除掉，
 */
export default {
  data() {
    return {
      // tabs的列表
      tabList: [
        {
          title: "首页",
          path: "/dashboard",
        },
      ],
      // 高亮的索引
      activeIndex: 0,
    };
  },
  methods: {
    skip(item, index) {
      this.$router.push(item.path);
      this.activeIndex = index;
    },
    // 判断当前路由是否已经存在于tabList中(是否重复)
    // isRepeat返回真，表示重复，返回假，表示不重复
    isRepeat(path) {
      return this.tabList.some((item) => {
        return path === item.path;
      });
    },
    //关闭
    handleClose(index){
        // 删除当前标签
        this.tabList.splice(index,1)
        // 判断删除的是否是当前高亮的
        if (index === this.activeIndex){
          //删除的是当前高亮的
          this.activeIndex = this.tabList.length - 1
          this.$router.push(this.tabList[this.activeIndex].path)
        }else{
          //删除的不是当前高亮的
          if (index < this.activeIndex){
            // 如果删除的索引小于高亮的索引
            // 
            this.activeIndex = this.activeIndex - 1
          }
        }
        // 删除之后，谁应该高亮
        // this.activeIndex = this.tabList.length - 1
        // 删除之后， 应该显示哪个路由信息
    }
  },
  watch: {
    $route: {
      handler(to) {
        // console.log('当前路由',to);
        // 如果当前存在嵌套路由，to是嵌套路由信息
        // 获取一级路由信息需要通过matched

        // 判断当前路由是否已经存在于tabList中(是否重复)
        if (this.isRepeat(to.fullPath)) {
          let index = this.tabList.findIndex(item=>{
            return item.path === to.fullPath
          })
          this.activeIndex = index;
          return;
        }
        if (to.matched[0].meta.level) {
          //只有一级
          this.tabList.push({
            title: to.matched[0].meta.title,
            path: to.fullPath,
          });
        } else {
          //有二级以上
          this.tabList.push({
            title: to.meta.title,
            path: to.fullPath,
          });
        }

        //处理高亮的索引
        this.activeIndex = this.tabList.length - 1

      },
      immediate: true,
    },
  },
};
</script>

<style lang="scss" scoped>
.el-tag {
  cursor: pointer;
}
</style>