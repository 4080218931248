<template>
  <el-breadcrumb separator="/">
    <el-breadcrumb-item :to="{ path: '/' }">首页</el-breadcrumb-item>
    <el-breadcrumb-item v-for="item in breadcrumbList" :key="item">{{
      item
    }}</el-breadcrumb-item>
  </el-breadcrumb>
</template>

<script>
export default {
  data() {
    return {
      breadcrumbList: [],
    };
  },
  watch: {
    $route: {
      /**
       * 分类讨论：
       * 1. 进入首页  /dashboard  to.matched[0].path = ''
       * 2. 进入只有一级菜单的路由 /notice/index  breadcrumbList:['公告管理']
       * 3. 进入有二级菜单的路由  /echarts/bar  breadcrumbList: ['图表管理','柱状图]
       */
      handler(to,from) {
        // console.log('当前路由信息',to);
        this.breadcrumbList = [];
        // matched是数组 matched[0]是一级路由信息 matched[1]是嵌套路由信息
        let { matched } = this.$route;
        if (matched[0].path === '')  return;

        if (matched[0].meta.level) {
          //只有一级菜单
          this.breadcrumbList.push(matched[0].meta.title);
          return;
        }

        //有二级菜单
        matched.forEach(item=>{
            this.breadcrumbList.push(item.meta.title)
        })
      },
      immediate: true,
    },
  },
};
</script>

<style>
</style>